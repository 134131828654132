var Utils = window.Utils || {};


Utils.formatCurrency = function(value) {
    return Intl.NumberFormat(Utils.LANGUAGE_CODE, {
        style: 'currency',
        currency: 'EUR',
    }).format(value);
};
Utils.formatIntCurrency = function(value) {
    return Utils.formatCurrency(value).slice(0, -3);
};


jQuery(function($, undefined) {
    var $window = $(window);
    var $header  = $('.header__bar');
    var hasClass = false;

    var onScroll = function() {
        if($window.scrollTop() > 78) {
            if(!hasClass) {
                hasClass = true;
                $header.addClass('header__bar--small');
            }
        } else {
            if(hasClass) {
                hasClass = false;
                $header.removeClass('header__bar--small');
            }
        }
    };

    $window.on('scroll', onScroll).trigger('scroll');
    // $('body').on('touchmove', onScroll).trigger('scroll');

});


jQuery(function($, undefined) {

    $('.property-filter__slider__input').each(function() {
        var $slider = $(this);
        var $sliderDiv = $slider.closest('.property-filter__slider');
        var $formGroup = $slider.closest('.form-group');
        var $inputMin = $formGroup.find('#id_week_rate__rate__gte');
        var $inputMax = $formGroup.find('#id_week_rate__rate__lte');
        var $value = $formGroup.find('.property-filter__value');
        var min = 0;
        var max = 10000;
        var step = 500;
        var initValue = [parseInt($inputMin.val()) || min, parseInt($inputMax.val()) || max];

        function setValue(value) {
            var v0 = value[0];
            var v1 = value[1];

            if ((v0 === min) && (v1 === max)) {
                $value.html('(Any)');
            } else {
                if (v1 === max) {
                    $value.html(Utils.formatIntCurrency(v0) + ' &mdash; ' + Utils.formatIntCurrency(v1) + '+');
                } else {
                    $value.html(Utils.formatIntCurrency(v0) + ' &mdash; ' + Utils.formatIntCurrency(v1));
                }
            }

        }

        setValue(initValue);

        $slider.slider({
            value: initValue,
            step: step,
            min: min,
            max: max,
        }).on('change', function() {
            var value = $slider.slider('getValue');

            if (value[0] === min) {
                $inputMin.val('');
            } else {
                $inputMin.val(value[0]);
            }
            if (value[1] === max) {
                $inputMax.val('');
            } else {
                $inputMax.val(value[1]);
            }

            setValue(value);
        });

        $value.on('click', function() {
            $sliderDiv.toggleClass('d-none');
        });
    });

});


jQuery(function($, undefined) {

    $('.google-map').each(function(index, el) {
        var $el = $(el);

        var position = $el.data('position').split(',');
        var lat = parseFloat(position[0]);
        var lng = parseFloat(position[1]);

        var mapOptions = {
            zoom: 14,
            scrollwheel: false,
            center: new google.maps.LatLng(lat, lng),
        };
        var map = new google.maps.Map(el, mapOptions);

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            map: map,
        });

    });

});


jQuery(function($, undefined) {
    var datepickerParams = {
        format: 'dd/mm/yyyy',
        todayBtn: true,
        todayHighlight: true,
        startDate: '+1d',
    };

    $('#property-enquiry-form, #advanced-enquiry-form')
        .find('.input-daterange')
        .datepicker(datepickerParams);

    $('body').on('submit', '#property-enquiry-form, #advanced-enquiry-form', function(event) {
        var $form = $(this);
        var $submit = $form.find('button[type=submit]');

        event.preventDefault();

        $submit.attr('disabled', true).append(' <i class="fas fa-spinner fa-spin"></i>');

        $.post($form.attr('action'), $form.serialize())
        .done(function(resp) {
            $form.html($(resp).html());

            grecaptcha.render($form.find('.g-recaptcha').get(0));

            $form.find('.input-daterange')
                 .datepicker(datepickerParams);
        })
        .fail(function() {
            $submit.attr('disabled', false).find('i').remove();
        });
    });
});


jQuery(function($, undefined) {

    $('#call_me_back-modal').on('submit', 'form', function(event) {
        var $form = $(this);
        var btns = $form.find('.btn');
        var submitBtn = $form.find('.btn[type=submit]');

        event.preventDefault();

        btns.attr('disabled', true);
        submitBtn.append(' ').append($('<i>').addClass('fas fa-spinner fa-spin'));

        $.post($form.attr('action'), $form.serialize())
        .done(function(data) {
            $form.html($(data).html());
            grecaptcha.render($form.find('.g-recaptcha-alt').get(0));
        })
        .fail(function() {
            btns.attr('disabled', false);
            submitBtn.find('i').remove();
            alert('Unable to process your request. Please try again later.');
        });
    });

    $('#call_me_back-modal').one('show.bs.modal', function (event) {
        grecaptcha.render($(this).find('.g-recaptcha-alt').get(0));
    })

});


jQuery(function($, undefined) {

    $('#add_your_property-modal').on('submit', 'form', function(event) {
        var $form = $(this);
        var btns = $form.find('.btn');
        var submitBtn = $form.find('.btn[type=submit]');

        event.preventDefault();

        btns.attr('disabled', true);
        submitBtn.append(' ').append($('<i>').addClass('fas fa-spinner fa-spin'));

        $.post($form.attr('action'), $form.serialize())
        .done(function(data) {
            $form.html($(data).html());
            grecaptcha.render($form.find('.g-recaptcha-alt').get(0));
        })
        .fail(function() {
            btns.attr('disabled', false);
            submitBtn.find('i').remove();
            alert('Unable to process your request. Please try again later.');
        });
    });

    $('#add_your_property-modal').one('show.bs.modal', function (event) {
        grecaptcha.render($(this).find('.g-recaptcha-alt').get(0));
    })

});
